.dropdownContainer {
    display: flex;
    align-items: center;
}

.addToCartButton {
    font-family: Montserrat;
    font-size: smaller;
    cursor: pointer;
    text-align: center;
}

.dropdownContainer {
    display: flex;
    align-items: center;
  }
  
  .addToCartButton {
    font-family: Montserrat;
    font-size: calc(9px + 8 * ((100vw - 320px) / 1600));
    text-transform: uppercase;
    font-style: normal;
    line-height: normal;
    width: 288px;
    color: #000;
    background-color: #ffffff; 
    cursor: pointer;
    margin-top: 20px;
    height: 36px;

    box-sizing: border-box;
    position: relative;
    border: 1px solid #000000;
    width: 100%;
    border-radius: 2px;
    padding: 2px 5px;
    direction: ltr;
    -ms-flex-align: center;
    cursor: pointer;
    min-height: 36px;
    pointer-events: all;
  }
  
.addToCartButton:hover {
    color: #ffffff;
    background-color: #AC8BD5;
    transition: 0.3s ease-in;
}

@media (max-width: 650px) {
    .dropdownContainer {
        width: 98vw;
    }
}