* {
    margin: 0;
    font-family: 'Montserrat', sans-serif;
    scroll-behavior: smooth;
}

img {
    pointer-events: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

body {
    background-color: white;
}