.CongratulationsModalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  
  .CongratulationsModal {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    max-width: 400px;
    width: 90%;
    text-align: center;
  }
  
  .CongratulationsModal h2 {
    margin-bottom: 35px;
    color: #000000;
  }
  
  .CongratulationsModal p {
    margin-bottom: 20px;
    color: #666;
  }
  
  .CongratulationsModal button {
    background-color: #AC8BD5;
    color: white;
    border: none;
    padding: 10px 40px;
    cursor: pointer;
  }
  
  .CongratulationsModal button:hover {
    background-color: #000000;
    transition: 0.3s ease-out;
  }