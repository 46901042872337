.product__list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.product__navigation {
    display: flex;
    justify-content: space-between;
}

.product__title {
    color: #000;
    font-size: calc(12px + 8 * ((100vw - 320px) / 1600));
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 4vh 2.2vw;
    

    user-select: none;
    -webkit-user-select: none;
}

.product__title:last-child {
    cursor: pointer;
}

.product__title:last-child:hover {
    color: #AC8BD5;
    transition: 0.3s ease-out;
}