.footer__wrapper {
    width: 100%;
}

.footer__title {
    margin-top: 4vh;
    margin-left: 2.2vw;
    margin-right: 2.2vw;
    padding-bottom: 6px;

    color: #000;
    font-family: Montserrat;
    font-size: calc(7px + 8 * ((100vw - 320px) / 1600));
    font-weight: 300;
    font-style: normal;
    line-height: normal;

    -ms-user-select: none; 
	-moz-user-select: none; 
	-webkit-user-select: none; 
	user-select: none;

    border-bottom: 1px solid black;
}

.footer__links {
    display: flex;
    justify-content: space-between;
    margin-left: 2.2vw;
    margin-right: 2.2vw;
    margin-top: 2.2vh;
}

.footer__link {
    color: #000;
    text-align: center;
    font-size: calc(7px + 8 * ((100vw - 320px) / 1600));;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    cursor: pointer;
    -ms-user-select: none; 
	-moz-user-select: none; 
	-webkit-user-select: none; 
	user-select: none;
}

.preorderLink {
    color: #AC8BD5;
}

.footer__link:hover {
    color: #AC8BD5;
    transition: 0.3s ease-out;
}

.preorderLink:hover {
    color: #000000;
    transition: 0.3s ease-in;
}

.contactsLink {
    margin-right: 36%;
}

img {
    max-width: 25%;
    height: auto;
    margin: auto;
    user-select: none;
    -webkit-user-drag: none;
}

@media only screen and (max-width: 767px) {
    .footer__links {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        flex-direction: column;
        align-items: flex-start;
        margin-top: 1vh;
    }
    .footer__link {
        margin-top: 2px;
        font-size: calc(12px + 8 * ((100vw - 320px) / 1600));
    }
    .footer__title {
        margin-top: 2vh;
        font-size: calc(12px + 8 * ((100vw - 320px) / 1600));
    }
}

@media (max-width: 490px) {
    img {
        max-width: 50%;
    }
}