.myModalNavigation {
    position: fixed;
    top: 0;
    bottom: 0;
    left: -37%;
    display: block;
    background: rgb(255, 255, 255);
    width: 37%;
    max-height: 100vh;
    transition: left 0.5s ease;
    /* overflow-x: hidden;
    overflow-y: scroll; */

    box-shadow: 3px 2px 20px 0px #00000085;
    z-index: 10;
}

.active {
    left: 0;

}

.myModalNavigation__title {
    color: #000;
    font-size: calc(9px + 8 * ((100vw - 320px) / 1600));
    font-style: normal;
    font-weight: 300;
    line-height: normal;

    margin-left: 10px;
    margin-top: 16px;
    margin-bottom: 16px;

    margin-left: 0.5vw;
    margin-top: 1.5vh;
    margin-bottom: 1.5vh;
    user-select: none;
}

.myModalNavigation__line {
    width: 100%;
    background-color: black;
    height: 1px;
}

.myModalNavigation__link {
    color: #000;
    font-size: calc(14px + 8 * ((100vw - 320px) / 1600));
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-left: 1.2vw;
    margin-bottom: 0.2vh;
}

.myModalNavigation__accordion {
    height: 6.7vh;
    width: 100%;
}

.myModalNavigation__content {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease;
    color: #000;
    font-size: calc(9px + 8 * ((100vw - 320px) / 1600));
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-left: 1.2vw;
    margin-top: 0.2vh;
}

.myModalNavigation__link {
    cursor: pointer;
    -ms-user-select: none; 
	-moz-user-select: none; 
	-webkit-user-select: none; 
	user-select: none;
}

.open {
    max-height: 55.6vh;
    transition: all height 2s ease;
}

li {
    list-style: none;
    
}

ul {
    padding: 0;
    cursor: pointer;
    -ms-user-select: none; 
	-moz-user-select: none; 
	-webkit-user-select: none; 
	user-select: none;
}

a {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease;
    color: #000;
    font-size: calc(11px + 6 * ((100vw - 320px) / 1600));
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 0.2vh;
    text-underline-offset: 0.2vh;
    text-transform: uppercase;
    transition: 0.3s ease-out;
}

a:hover {
    color: #AC8BD5;
}

p {
    color: #AC8BD5;
    margin-bottom: 10px;
}

.myModalNavigation__logo {
    display: flex;
    align-items: center;
    cursor: default;
    justify-content: center;
    margin-top: 20%;
}

.myModalNavigation__link:hover {
    color: #AC8BD5;
    transition: 0.3s ease-out;
}

.purple {
    color: #AC8BD5;
}

.purple:hover {
    color: #000000;
    transition: 0.3s ease-in;
}

.myModalNavigation__logo img {
    max-width: 50%;
    height: auto;
}

@media only screen and (max-width: 767px) {
    .myModalNavigation {
        width: 100%;
        left: -100vw;
        box-shadow: none;
    }
    .active {
        left: 0;
        overflow: hidden;
    }
    .myModalNavigation__content {
        font-size: calc(13px + 8 * ((100vw - 320px) / 1600));
    }
    .myModalNavigation__title {
        font-size: calc(12px + 8 * ((100vw - 320px) / 1600));
    }
}

