.productPage__wrapper {
    display: flex;
    margin-left: 2.2vw;
    margin-right: 2.2vw;
    margin-top: 38px;
    flex-wrap: wrap;
}
.productPage__images {
    display: flex;
    width: 55vw;
    min-height: 80vh;
    max-height: fit-content;
    border: 1px solid black;
    flex-wrap: wrap;
    margin-right: 5%;
    user-select: none;
    align-items: center;
    justify-content: center;
    cursor: grab;
}

.productPage__images img {
    max-width: 60%;
    margin: auto;
    user-select: none;
}

.productPage__images :active {
    cursor: grabbing;
}

.ProductPage__brand a {
    color: #AC8BD5;
    font-family: Montserrat;
    font-size: calc(30px + 8 * ((100vw - 320px) / 1600));
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    margin-right: auto;
    margin-bottom: 5%;
    user-select: none;
    cursor: pointer;
    text-decoration: none;
}
.ProductPage__brand span {
    margin-left: 5px;
    margin-right: 5px;
    font-size: calc(30px + 8 * ((100vw - 320px) / 1600));
}
.ProductPage__brand a:hover {
    color: #000;
    transition: 0.3s ease-in;
}
.ProductPage__brand {
    color: #AC8BD5;
    font-family: Montserrat;
    font-size: calc(30px + 8 * ((100vw - 320px) / 1600));
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    margin-right: auto;
    margin-bottom: 5%;
    user-select: none;
}
.ProductPage__colorway{
    color: #000;
    font-family: Montserrat;
    font-size: calc(30px + 8 * ((100vw - 320px) / 1600));
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    max-width: 450px;
    text-align: left;
    margin-bottom: 5%;
}
.ProductPage__price {
    color: #000;
    font-family: Montserrat;
    font-size: calc(30px + 8 * ((100vw - 320px) / 1600));
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 10%;
    max-width: 450px;
    user-select: none;
    margin-bottom: 5%;
}
.description__block {
    min-height: 25%;
    max-height: fit-content;
    max-width: 576px;
    border: 1px solid black;
    display: flex;
    flex-direction: column;
    user-select: none;
}
.description__title {
    color: #000;
    font-family: Montserrat;
    font-size: calc(9px + 8 * ((100vw - 320px) / 1600));
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    height: fit-content;
    padding-left: 10px;
    margin-top: 10px;
    padding-bottom: 5px;
    width: calc(100% - 10px);
    border-bottom: 1px solid black;
    user-select: none;
}
.description__text {
    color: #000;
    font-family: Montserrat;
    font-size: calc(9px + 8 * ((100vw - 320px) / 1600));
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    height: fit-content;
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 20px;
    max-width: 100%;
    text-align: justify;
    user-select: none;
}
.singleImage {
    display: flex;
    width: 55vw;
    min-height: 80vh;
    max-height: fit-content;
    border: 1px solid black;
    flex-wrap: wrap;
    margin-right: 80px;
    user-select: none;
    align-items: center;
    justify-content: center;
    cursor: default;
}

.ShippingInformation__wrapper {
    display: flex;
    margin-left: 2.2vw;
    margin-right: 2.2vw;
    margin-top: 38px;
    flex-wrap: wrap;
    width: calc(100% - 4.4vw);
    margin-bottom: 75px;
}

.ShippingInformation__title {
    color: #000;
    font-family: Montserrat;
    font-size: calc(12px + 8 * ((100vw - 320px) / 1600));
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    width: 100%;
}

.ShippingInformation {
    width: 100%;
    color: #000;
    font-family: Montserrat;
    font-size: calc(12px + 8 * ((100vw - 320px) / 1600));
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.ListWrapper {
    list-style: outside;
    margin-top: 25px;
    margin-left: 25px;
    color: #AC8BD5;
    width: 50%;
}

.description__sizeHint {
    max-width: 576px;
    text-align: justify;
    overflow: hidden;
    transition: max-height 0.5s ease;
    color: #000;
    font-size: calc(9px + 8*((100vw - 320px) / 1600));
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 20px;
}

.button {
    cursor: pointer;
    text-decoration: underline;
    color: #000;
    font-size: calc(9px + 8*((100vw - 320px) / 1600));
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 0.2vh;
    text-underline-offset: 0.2vh;
    text-transform: uppercase;
    transition: 0.3s ease-out;
}

.hint {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    padding: 5px;
    border: 1px solid black;
    -webkit-user-select: none;
    user-select: none;
    font-size: calc(9px + 8 * ((100vw - 320px) / 1600));
    transition: all 0.25s ease;
}

.active {
    transform: rotate(45deg);
    transition: all 0.25s ease;
}

.hint:hover, .hint:focus, .hint:focus-within {
    color: #AC8BD5;
    transition: all 0.5s ease;
    outline: 0;
    box-shadow: 0 0 0 3px #AC8BD5 !important;
    border-color: #AC8BD5;
}

.SizeNotAvailable {
    transition: all 0.25s ease;
    display: flex;
    align-items: center;
    max-height: 55.6vh;
    margin-top: 20px;
}

.hidden {
    max-height: 0;
    overflow: hidden;
    transition: all 0.25s ease;
    margin-top: 20px;
}

.ContactUs {
    max-width: 576px;
    text-align: justify;
    overflow: hidden;
    transition: max-height 0.5s ease;
    color: #000;
    font-size: calc(9px + 8 * ((100vw - 320px) / 1600));
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.button:hover {
    color: #AC8BD5;
}

@media (max-width: 1441px) {
    .productPage__images {
        max-width: 48vw;
    }
    .ProductInfo_wrapper{
        width: 43%;
    }
}

@media (max-width: 650px) {
    .productPage__wrapper {
        margin-left: 1vw;
        margin-right: 1vw;
    }
    .productPage__images {
        width: 98vw;
        max-width: none;
        margin-right: 0;
        min-height: 67vh;
    }
    .ProductPage__brand {
        margin-top: 5%;
    }
    .ProductInfo_wrapper {
        width: 100%;
    }
    .ProductPage__colorway{
        max-width: none;
        font-size: calc(26px + 8 * ((100vw - 320px) / 1600));
    }
    
    .description__block{
        max-width: none;
    }
    .description__title {
        font-size: calc(12px + 8 * ((100vw - 320px) / 1600));
    }
    .description__text {
        font-size: calc(12px + 8 * ((100vw - 320px) / 1600));
    }
    .description__sizeHint {
        max-width: none;
        font-size: calc(12px + 8 * ((100vw - 320px) / 1600));
        margin-bottom: 10px;
    }
    .button {
        max-width: fit-content;
    }
    .ProductPage__brand a {
        font-size: calc(26px + 8 * ((100vw - 320px) / 1600));
    }
    .ProductPage__brand span {
        font-size: calc(26px + 8 * ((100vw - 320px) / 1600));
    }
    .ProductPage__price {
        font-size: calc(26px + 8 * ((100vw - 320px) / 1600));
    }
    .ContactUs {
        font-size: calc(12px + 8 * ((100vw - 320px) / 1600));
        width: 100%;
    }
}