.HeaderNavigation__wrapper {
    display: flex;
    margin-left: 2.2vw;
    margin-right: 2.2vw;
    justify-content: space-between;
    transition: top 0.5s ease;
    top: -100px;
}

.fixed {
  position: fixed;
  top: 0;
  left: -16px;
  right: 0;
  z-index: 997;
  padding-bottom: 0;
  background-color: white;
  width: 100% !important;
  animation: slideDown 0.5s ease;
  -webkit-overflow-scrolling: touch;
}

.notfixed {
  position: absolute;
  top: 150px;
  width: 100% !important;
  top: 0;
  left: -16px;
  right: 0;
  animation: slideUp 1s ease;
  animation-play-state: running;
  background-color: white;
  -webkit-overflow-scrolling: touch;
}

@keyframes slideUp {
  from {
      top: 0;
      position: fixed;
  }
  to {
      top: -150px;
  }
}

@keyframes slideDown {
  from {
      top: -150px;
  }
  to {
      top: 0;
  }
}

.HeaderNavigation {
    margin-top: 38px;
    color: #000;
    font-size: calc(7px + 8*((100vw - 320px) / 1600));
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    white-space: nowrap;

    cursor: pointer;
    -ms-user-select: none; 
	  -moz-user-select: none; 
	  -webkit-user-select: none; 
	  user-select: none;
}

.preorderButton {
    color: #AC8BD5;
    margin-right: 100px;
}

.HeaderNavigation:hover {
    color: #AC8BD5;
    transition: 0.3s ease-out;
}

.preorderButton:hover {
    color: #000;
    transition: 0.3s ease-in;
}

.SearchModalContainer {
  position: fixed;
  top: 0; 
  left: 0;
  width: 100%;
  background-color: rgba(255, 255, 255); /* Прозрачный цвет фона */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.ModalContent {
  width: 100%;
}

.ModalSearch {
  display: flex;
  justify-content: center;
  padding-bottom: 50px;
}

.ModalSearch img {
  position: absolute;
  right: 73%;
}

.CloseButton {
  position: absolute;
  top: 35px;
  left: 73%;
  cursor: pointer;
  width: 30px;
  height: 30px;
}

.CloseButton img {
  width: 20px;
  height: 20px;
  max-width: 100%;
  object-fit: cover;
  right: 9px !important;
  top: 3px !important;
}

.ModalSearch input {
  width: 46vw;
  height: 30px;
  border-radius: 1px;
  padding-left: 40px;
  margin-top: 30px;
  border: #000 1px solid;
}

.ModalSearch input:focus-visible {
  outline: 2px solid #AC8BD5;
  border: 1px solid #AC8BD5;
  border-bottom: 2px solid #AC8BD5;
}

.product__card {
  display: flex;
  height: 100%;
  flex-direction: column;
  width: 23%;
  border-top: 1px solid black; 
  border-right: 1px solid black; 
  border-bottom: 1px solid black; 
  height: 40vh;
  cursor: pointer;
}

.product__card:nth-child(-1n +4) {
  border-top: 2px solid black;
  border-left: 1px solid black;
}
.product__card:not(:nth-child(-1n +4)) {
  border-bottom: 2px solid black;
  border-left: 1px solid black;
}

.product__card:hover {
  border-color: #AC8BD5;
  box-shadow: 0 0 0 1px #AC8BD5, 0 0 0 2px #AC8BD5;
  z-index: 2;
  transition: 0.3s ease-out;
}

.product__card img {
  max-width: 85%;
  margin-bottom: 0;
  object-position: center;
  margin-bottom: 10px;
}

.product__card h2 {
  color: #000;
  font-family: Montserrat;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-left: 5px;
  margin-top: 5px;
  margin-right: 5px;
}

.product__card:hover h2 {
  color: #AC8BD5;
}

.product__card p {
  margin: 8px 0;
}

.Products__Wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  overflow-y: scroll;
  overscroll-behavior: auto;
  height: 100%;
  position: fixed;
  background-color:white;
  left: 0;
  margin-bottom: 100px;
  padding-left: 4%;
  padding-top: 50px;
}

.product__card:last-child{
  margin-bottom: 300px;
}

.CartWrapper {
  cursor: pointer;
  -ms-user-select: none; 
  -moz-user-select: none; 
  -webkit-user-select: none; 
  user-select: none;
}

.BrandsModalContainer {
  position: absolute;
  top: 70px;
  left: 0;
  width: 100%;
  background-color: rgb(255, 255, 255);
  display: flex;
  z-index: 999;
  flex-direction: column;
  box-shadow: 0px 7px 11px 0px rgba(0, 0, 0, 0.3);
}

.BrandsModalContent {
  background-color: white;
  border-radius: 5px;
  display: flex;
  flex-wrap: wrap;
}

.BrandsModalContainer h2 {
  font-size: 1.04vw;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 20px;
  margin-top: 20px;
  height: fit-content;
  margin-left: 108px;
  color: #AC8BD5;
}

.BrandsModalContent p {
  width: 350px;
  color: #000;
  text-align: center;
  font-size: calc(7px + 8*((100vw - 320px) / 1600));
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  text-transform: uppercase;
  margin-bottom: 25px;
}

p:hover {
  transition: 0.3s ease-out;
  color: #AC8BD5;
}

@media (max-width: 1025px) {
  .HeaderNavigation {
    margin-top: 42px;
  }
  .Products__Wrapper {
    padding-left: 50px;
  }
  .product__card img {
    object-position: center bottom;
    margin-bottom: 10px;
    height: auto;
  }
}
@media (max-width: 830px) {
    .preorderButton {
      color: #AC8BD5;
      margin-right: 2%;
  }
}

@media (max-width: 770px) {
  .ModalSearch img{
    right: 72%;
  }
  .Products__Wrapper{
    padding-left: 3%;
  }
  .product__card {
    width: 31%
  }
  .product__card:nth-child(-1n +3) {
    border-top: 2px solid black;
    border-left: 1px solid black;
  }
  .product__card:nth-child(3n + 4) {
    border-top: 1px solid black;
    border-left: 2px solid black;
  }
  .product__card:nth-child(1) {
    border-left: 2px solid black;
  }
  .product__card:nth-child(3n + 3) {
    border-right: 2px solid black;
  }
  .product__card:not(:nth-child(-1n +4)) {
    border-bottom: 1px solid black;
    /* border-left: 0px solid black; */
  }
}

@media (max-width: 765px) {
  .HeaderNavigation {
    display: none;
  }
  .SearchWrapper {
    display: none;
  }
  .HeaderNavigation:nth-child(9) {
    display: flex;
    font-size: 15px;
    margin-left: 65%;
  }
  .ModalSearch img {
    left: 10px;
  }
  .SearchModalContainer {
    position: absolute;
    background-color: rgb(255 255 255 / 0%);
    justify-content: flex-start;
    box-shadow: none;
    width: auto;
  }
  .ModalSearch {
    margin-left: 5px;
    width: 48vw;
    padding: 0;
  }
  .CloseButton {
    left: 89%;
  }
  .HeaderNavigation__wrapper {
    width: 97%;
    height: 11%;
    margin: 0;
    left: 0;
  }
  .CartWrapper {
    margin-right: 8px;
  }
}

body {
  transition: padding-top 0.5s ease;
}

@media (max-width: 572px) {
    .CloseButton {
      left: 84%;
  }
}

@media (max-width: 468px) {
  .Products__Wrapper {
    padding-left: 2px;
  }
  .product__card {
    width: 49%;
    height: 35vh;
    border-bottom: 0px solid black;
  }
  .product__card:nth-child(2n) {
    border-left: 0px solid #000;
    border-top: 1px solid black;
  }
  .product__card:nth-child(2n+1) {
    border-right: 1px solid #000;
    border-top: 1px solid black;
  }
  .product__card:not(:nth-child(-1n +4)) {
    border-bottom: 0px solid black;
  }
  .product__card:nth-last-child(-n+2) {
    border-bottom: 1px solid black;
  }
}

@media (max-width: 460px) {
  .HeaderNavigation:nth-child(9) {
      display: flex;
      font-size: 15px;
      margin-left: 60%;
  }
}